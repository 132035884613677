import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import './assets/scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// import i18n (needs to be bundled ;))
import './i18n';
const app = (
  <React.StrictMode>
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          'client-id':
            'AcDeZCKKeBt8INCHwbuEeZp8RcS8lvJe24LuaiNho7ZFESBZG3OhbTVyWNzEdSPVb1LcBZQRsJuzKolj',
          vault: true,
          intent: 'subscription',
          currency: 'USD'
        }}
      >
        <GoogleOAuthProvider clientId="217739310813-haekd9stq4j6eli7rsfa9qdqb9j3q1n8.apps.googleusercontent.com">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </PayPalScriptProvider>
    </Provider>
  </React.StrictMode>
);
createRoot(document.getElementById('root')).render(app);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
