import * as React from 'react';
const SignIn = React.lazy(() => import('./Pages/Authentication/SignIn'));

const route = [
  // { path: '/auth/signup', exact: true, name: 'Signup 1', component: SignUp },
  { path: '/', exact: true, name: 'Signin 1', component: SignIn }
  // { path: '/auth/reset-password', exact: true, name: 'Reset Password 1', component: ResetPassword },
  // { path: '/auth/change-password', exact: true, name: 'Change Password', component: ChangePassword },
  // { path: '/auth/profile-settings', exact: true, name: 'Profile Settings', component: ProfileSettings },
  //     { path: '/maintenance/error', exact: true, name: 'Error', component: Error },
  //     { path: '/maintenance/coming-soon', exact: true, name: 'Coming Soon', component: ComingSoon },
  //     { path: '/maintenance/offline-ui', exact: true, name: 'Offline UI', component: OfflineUI }
];
export default route;
