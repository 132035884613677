const Config = {
  initialPath: '/',
  // defaultPath: '/dashboard/default',
  pathSignin: '/',
  defaultPath: '/',
  // basename: '/able-pro/react/default',
  basename: '/',
  layout: 'vertical',
  subLayout: '',
  collapseMenu: false,
  layoutType: 'menu-light',
  headerBackColor: 'header-blue',
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: true,
  boxLayout: false
};
export default Config;
