import * as React from 'react';

const DashboardDefault = React.lazy(() => import('./Pages/Dashboard'));
const Project = React.lazy(() => import('./Pages/Project'));
const ProjectCRUD = React.lazy(() => import('./Pages/Projects/ProjetoForm'));
const ProjectAnalytics = React.lazy(
  () => import('./Pages/Projects/ProjectAnalytics')
);
const ProjectsSearch = React.lazy(() => import('./Pages/Projects/Search'));
const ProjectsProfile = React.lazy(() => import('./Pages/Profile'));
const ProjectAnalyticsShare = React.lazy(
  () => import('./Pages/Projects/FormPages/ShareForm')
);
const Payment = React.lazy(() => import('./Pages/Payment'));
const Admin = React.lazy(() => import('./Pages/Admin'));

const routes = [
  {
    path: '/dashboard/',
    exact: true,
    name: 'Dashboard',
    component: DashboardDefault
  },
  { path: '/dashboard/adm', exact: true, name: 'Adm', component: Admin },
  // { path: '/projects', exact: true, name: 'Projects', component: ProjecIndex },
  { path: '/projects/', exact: true, name: 'Project', component: Project },
  {
    path: '/projects/new',
    exact: true,
    name: 'NewProject',
    component: ProjectCRUD
  },
  {
    path: '/projects/:id/edit',
    exact: true,
    name: 'EditProject',
    component: ProjectCRUD
  },
  {
    path: '/projects/:id/analytics',
    exact: true,
    name: 'Analytics',
    component: ProjectAnalytics
  },
  {
    path: '/projects/:id/share',
    exact: true,
    name: 'Share',
    component: ProjectAnalyticsShare
  },
  {
    path: '/projects/search',
    exact: true,
    name: 'Search',
    component: ProjectsSearch
  },
  { path: '/payment', exact: true, name: 'Payment', component: Payment },
  {
    path: '/profile/',
    exact: true,
    name: 'Profile',
    component: ProjectsProfile
  }
];
export default routes;
