import * as React from 'react';
import { lazy, Suspense, createRef } from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory
} from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routesOnePage from '../route';
import routes from '../routes';
const AdminLayout = lazy(() => import('./layout/AdminLayout'));

export const historyRef = createRef();

const App = () => {
  const location = useLocation();

  const history = useHistory();
  historyRef.current = history;

  return (
    <Suspense fallback={<Loader />}>
      <ScrollToTop>
        <Switch location={location} key={location.pathname}>
          {routesOnePage.map((route) => {
            return route.component ? (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ) : null;
          })}

          {routes.map((route) => {
            return route.component ? (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                component={route.component}
              >
                {' '}
                <AdminLayout />{' '}
              </Route>
            ) : null;
          })}
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
};

export default App;
